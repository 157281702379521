

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'

  

  interface pathToDeleteProps {
    currentDeleteDocsInfo: string,
    loadingInfoAboutDelete: boolean;
    errorInfoAboutDelete: string | null;
  }
  
 
  const initialState: pathToDeleteProps = {
    currentDeleteDocsInfo: "",
    loadingInfoAboutDelete: false,
    errorInfoAboutDelete: null,
  };
  
  
  export const fetchfolderDelete = createAsyncThunk(
    'directoryOne/deleteOneObject',
    async (arg:string | null, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/delete`, {path: arg});
        return response.data; // Assuming this returns Directory[]
      } catch (errorInfoAboutDelete: any) {
        return rejectWithValue(errorInfoAboutDelete.message);
      }
    }
  );
  
  export const folderDelete = createSlice({
    name: 'pathDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchfolderDelete.pending, (state) => {
          state.loadingInfoAboutDelete = true;
          state.errorInfoAboutDelete = null;
        })
        .addCase(fetchfolderDelete.fulfilled, (state, action: PayloadAction<string>) => {
          state.loadingInfoAboutDelete = false;
          state.currentDeleteDocsInfo = action.payload;
        })
        .addCase(fetchfolderDelete.rejected, (state, action: PayloadAction<any>) => {
          state.loadingInfoAboutDelete = false;
          state.errorInfoAboutDelete = action.payload;
        });
    },
  });

  
  export default folderDelete.reducer;
  