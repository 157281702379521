import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface refFolderProps {
    refFolder: any;
}

const initialState: refFolderProps = {
    refFolder: null, 
};

export const globalRefFolderSlice = createSlice({
    name: 'path',
    initialState,
    reducers: {
        setCurrentRef: (state, action: PayloadAction<any>) => {
            // Действие, которое устанавливает текущий путь (все сегменты пути)

           state.refFolder = action.payload
   
        },
        
    },
});

export const { setCurrentRef } = globalRefFolderSlice.actions;

export const selectPath = (state: RootState) => state.globalRefFolderSlice.refFolder;


export default globalRefFolderSlice.reducer;
