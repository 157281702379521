
import { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPath, setDeleteLastPath } from "../../app/redux/slices/CurrentDirectorySlice"
import { fetchFolderCurrent, setCurrentFolder } from "../../app/redux/slices/currentFolderInfo"
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
import { fetchDropDownSlice } from '../../app/redux/slices/DropDownSlice'
import { fetchGenerateLink } from '../../app/redux/slices/generateLinkSlice'
import { fetchDirectories } from "../../app/redux/slices/getAllDirectories"
import { fetchDirectoriesUnder } from "../../app/redux/slices/getAllDirectoriesUnder"
import { AppDispatch } from "../../app/redux/store"
import BottomContext from "../../entities/BottomContext/BottomContext"
import { ContextMenu } from "../../entities/ContextMenu/ContextMenu"
import Folder from "../../entities/Folder/Folder"
import getFileExtension from '../../features/FindExten'
import splitText from '../../features/splitText'
import imgCopy from '../../shared/copy.svg'
import imgDownload from '../../shared/icons8.svg'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFace, FolderFaceNew } from '../../types/types'
import style from './Context.module.scss'
const Context = ( )=> {
   
    const {directories, loading, error} = useSelector((state:any)=>state.directory) 
    const directoryUnderSlice = useSelector((state:any)=>state.directoryUnderSlice) 
    const {currentFolderInfo} = useSelector((state:any)=>state.currentFolder)
    const {path} =useSelector((state:any)=>state.CurrentDirectorySlice)
    const {loadingInfo} = useSelector((state:any)=>state.currentFolder)
    const {errorInfo} = useSelector((state:any)=>state.currentFolder)
    const refBottom = useRef<any>(null)
  
    const dispatch = useDispatch<AppDispatch>();
    const {image} = useSelector((state:any)=>state.CurrentImageSlice)
    const {generateLink} = useSelector((state:any)=>state.generateLinkSlice)
    useEffect(()=>{
        
        if(image) {
        dispatch(fetchGenerateLink(image))
        setIsOpen(true)
        }
    },[image])
  
    useEffect(()=>{
        dispatch(fetchDirectoriesUnder())
        dispatch(fetchDropDownSlice())
        dispatch(fetchDirectories())
        dispatch(fetchFolderCurrent(path.join("/")))
 
        
    },[])
    useEffect(()=>{

        if(loadingInfo === false && errorInfo === null) {
          
            dispatch(setCurrentFolder(currentFolderInfo))

        }
    },[currentFolderInfo, errorInfo, loadingInfo])
    let [click, setCLick] =useState<number>(0)
    const PathForDelete = useSelector((state:any)=>state.CurrentDirectorySlice.path);
    useEffect(()=>{
  
  if(click > 0 && PathForDelete.join("/").length >0) {
    dispatch(fetchFolderCurrent(PathForDelete.join("/")))
    dispatch(setCurrentPath(PathForDelete.join("/")))
  }
    },[click])
    const handleDeleteLastPath = () => {
        setCLick(click+=1)
        dispatch(setDeleteLastPath());
    };
    let [contentsZero, setContentsZero] = useState<any>(null)

    useEffect(() => {
     
        const ZeroObject: FolderFaceNew = {
          name: "...",
          type: 'directory',
          path: "",
          isHaveContent: false,
          index: 0,
          contents: []
        };
    
        if (currentFolderInfo) {
          
          const newContents = [ZeroObject, ...currentFolderInfo];
          setContentsZero(newContents); 
        }
      }, [currentFolderInfo]);
  
    const [isContextMenu, setIsContextMenu] = useState<any>(false);
    const handleContextMenu = (event: React.MouseEvent) => {
      
        event.preventDefault(); 
        const targetElement = event.target as HTMLElement;
        const elementId = targetElement.id;
       
        if(elementId !== 'inner'){

            setIsContextMenu(!isContextMenu)}

        };
    const handleContextMenuBack = () => {
        setIsContextMenu(false);
    };
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const handleOpen = () => {
        setIsOpen(!isOpen)
        dispatch(setCurrentImagePath(""))
    }

    // useEffect(()=>{
    //     if(generateLink) {
    //         console.log("generateLink", generateLink);
    //          const result = getFileExtension(generateLink.link)
    //          console.log("result", result);
    //     }
    // },[generateLink])
    const setSrc = (): string => {
        let extenOne: null | string = null;
        let typeOne: null | 'directory' = null;
        const exten: string = getFileExtension(generateLink.link);
        if (exten.trim().length === 0) {
            extenOne = null;
        } else if (exten.trim().length > 0) {
            extenOne = exten;
        }
        // if (type === undefined) {
        //     typeOne = null;
        // } else if (type === 'directory') {
        //     typeOne = 'directory';
        // }
       
        const result: string = arrIcons(extenOne, typeOne);
        if(extenOne === 'jpg' || extenOne === 'png') {
            return generateLink.link
        }
        return result;
    };
    return (
        <div className={style.wrapperRoot}>
            <div className={style.wrapperContext}>
            {
                directoryUnderSlice.directories.map((element:FolderFace)=>{
                    return (
                        <Folder  key={element.path}
                        name={element.name}
                        type={element.type}
                        path={element.path}
                        isHaveContent={element.isHaveContent}
                        contents={element.contents}
                        level={0}  />
                    )
                })
                
            }

           <div ref={refBottom} id="out" onClick={handleContextMenuBack} onContextMenu={handleContextMenu} className={style.wrapperContextHorizontal} >
         
          
           {
                contentsZero !== null && contentsZero.map((element:FolderFaceNew, index:number)=>{
                    return (
                      
                        <BottomContext
                        key={element.path}
                        {...element}
                        index={index}
                        />
                    )
                })
            }
          
             {isContextMenu && refBottom.current.id !== "inner" && <ContextMenu path={path.join("/")} isContext={true} type={""} />}
           </div>

            </div>
          <div onClick={()=>{
            setIsContextMenu(false)
          }} className={style.wrapperImg}>
           {generateLink &&         
                <>
                    <img className={isOpen?style.openImage:style.closeImage} src={setSrc()} />
                    {isOpen && <div className={style.wrapperButtons}>
                        <button className={style.btnClose} onClick={handleOpen}></button>
                        <a href={generateLink.link} download={generateLink.link} target='_blank' className={style.btnDownload}><img className={style.imgDownload} src={imgDownload} alt='d' /></a>
                        <CopyToClipboard text={generateLink.link} >
                          <div className={style.clipboard}>{splitText(generateLink.link, 20)}
                            <img className={style.imgCopy} src={imgCopy} alt="copy" />
                          </div>
                        </CopyToClipboard>
                    </div>}
                </>
           }
          </div>
            
        </div>
        
    )
}

export default Context
