import React from 'react'
import style from './Path.module.scss'

interface PathProps {
    path: string[];
}

const Path: React.FC<PathProps> = ({ path }) => {
    const stringpath = path.length > 0?path.join("/"):"/" 
    return (
        <div className={style.wrapperPath}>
            {stringpath}
        </div>
    );
};

export default Path;
