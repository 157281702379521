import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchCreateFile } from './CreateFileSlice'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'

interface createFileProps {
  path: string,
  fileName: string,
  content: string
}

export const createFileSeqActionSlice = ({
  path,
  fileName,
  content

}: createFileProps) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  try {
    await dispatch(fetchCreateFile({ path, fileName,  content})).unwrap();
    await dispatch(fetchFolderCurrent(path)).unwrap();
    await dispatch(fetchDirectoriesUnder()).unwrap()
    
  } catch (error) {
    console.error('Ошибка выполнения:', error);
  }
};
