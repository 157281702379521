
import './app/scss/index.scss'
import { SearchPage } from './pages/SearchPage'
import Header from './widgets/Header/Header'

function App() {
  return (
    <div className="App">
      <Header />
      <SearchPage/>
    </div>
  );
}

export default App;
