import { configureStore } from '@reduxjs/toolkit'
import directoryReducer from './slices/getAllDirectories'

import folderDelete from './slices/AsyncDeleteDocSlice'
import folderCreateSlice from './slices/CreateFolderSlice'
import CurrentDirectorySlice from './slices/CurrentDirectorySlice'
import currentFolder from './slices/currentFolderInfo'
import CurrentImageSlice from './slices/CurrentImageSlice'
import dropDownSlice from './slices/DropDownSlice'
import generateLinkSlice from './slices/generateLinkSlice'
import directoryUnderSlice from './slices/getAllDirectoriesUnder'
import globalRefFolderSlice from './slices/globalRefFolderSlice'
import fetchMoveSlice from './slices/moveFolderSlice'
import renameFileSlice from './slices/renameFileSlice'

const store = configureStore({
  reducer: {
    directory: directoryReducer,
    currentFolder,
    CurrentDirectorySlice,
    folderDelete,
    fetchMoveSlice,
    directoryUnderSlice,
    globalRefFolderSlice,
    dropDownSlice,
    CurrentImageSlice,
    generateLinkSlice,
    folderCreateSlice,
    renameFileSlice
  
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
