import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchfolderDelete } from './AsyncDeleteDocSlice'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'

interface DeleteFolderParams {
  path: string;
  pathFolder: string
}

export const deleteFolderSeqActionSlice = ({ path,pathFolder }: DeleteFolderParams) => 
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      
      await dispatch(fetchfolderDelete(path)).unwrap();
      
      
      await dispatch(fetchFolderCurrent(pathFolder)).unwrap();
      await dispatch(fetchDirectoriesUnder()).unwrap()
      
    } catch (error: any) {
      console.error('Error during folder deletion:', error);
      // Optionally, dispatch an error action to handle the error in the UI
      // dispatch(handleError(error.message));
    }
};
