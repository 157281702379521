import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectories } from './getAllDirectories'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'
import { fetchMove } from './moveFolderSlice'

interface PerformSequentialActionsParams {
  newPath: string;
  oldPath: string;
  PathOne: { path: string[] };
}

export const performSequentialActions = ({
  newPath,
  oldPath,
  PathOne
}: PerformSequentialActionsParams) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  try {
    await dispatch(fetchMove({ newPath, oldPath })).unwrap();
    await dispatch(fetchFolderCurrent(PathOne.path.join('/'))).unwrap();
    await dispatch(fetchDirectories()).unwrap();
    await dispatch(fetchDirectoriesUnder()).unwrap();
  } catch (error) {
    console.error('Ошибка выполнения:', error);
  }
};
