import style from './Header.module.scss'

const Header = () => {
  return (
    <header className={style.wrapperHeader}>
        <div className={style.headerTitle}>
            Файловый проводник
        </div>
    </header>
  );
}

export default Header;
