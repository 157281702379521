//http://localhost:5000/media/getFolder

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'
interface pathToDeleteProps {
    renameFile: string,
    renameFileLoading: boolean;
    errorFolderCreate: string | null;
  }
  

  const initialState: pathToDeleteProps = {
    renameFile: "",
    renameFileLoading: false,
    errorFolderCreate: null,
  };
  interface renameFileProps {
		path: string | null,
		newName: string,
    
	}
  
  export const fetchRenameFile = createAsyncThunk(
    'context/renameFile',
    async (arg:renameFileProps, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/rename`,  {...arg});
        return response.data; 
      } catch (errorFolderCreate: any) {
        return rejectWithValue(errorFolderCreate.message);
      }
    }
  );
  
  export const renameFileSlice = createSlice({
    name: 'renameFile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchRenameFile.pending, (state) => {
          state.renameFileLoading = true;
          state.errorFolderCreate = null;
        })
        .addCase(fetchRenameFile.fulfilled, (state, action: PayloadAction<string>) => {
          state.renameFileLoading = false;
          state.renameFile = action.payload;
        })
        .addCase(fetchRenameFile.rejected, (state, action: PayloadAction<any>) => {
          state.renameFileLoading = false;
          state.errorFolderCreate = action.payload;
        });
    },
  });

  
  export default renameFileSlice.reducer;
  