import React, { useState } from 'react'
import { useSelector } from "react-redux"
import Context from "../widgets/Context/Context"
import Path from "../widgets/Path/Path"
import Search from "../widgets/Search/Search"
import style from './SearchPage.module.scss'

export const SearchPage: React.FC = () => {
   let [click, setCLick] = useState(0)
   const {path} = useSelector((state:any)=>state.CurrentDirectorySlice);
   const {currentFolderInfo} = useSelector((state:any)=>state.currentFolder);
   const {directoriesDropDown} = useSelector((state:any)=>state.dropDownSlice)

   
   return (
      <div className={style.wrapperComponents}>
        
         <Search directories={currentFolderInfo} path={path} />
         <Path path={path} />
         <Context />
        
      </div>
   );
};
   