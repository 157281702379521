import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchFolderCurrent, setCurrentFolder } from '../../app/redux/slices/currentFolderInfo'
import { AppDispatch } from '../../app/redux/store'
import splitText from '../../features/splitText'
import { DropDownComponentProps, DropDownProps } from '../../types/types'
import style from './DropDown.module.scss'
const DropDown = ({ arg: dropDownProps, path }: DropDownComponentProps) => {
  
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [currentName, setCurrentName] = useState<string>('')
  const dispatch = useDispatch<AppDispatch>();
  const handleOpenSwitcher = () => {
    setIsOpen(!isOpen)
  }
  const handleClick = (name:string) => {
      if(name === currentName) {
        
        dispatch(fetchFolderCurrent(path.join("/")))
       
        setCurrentName("")
        setIsOpen(!isOpen)
        return
      }
      setCurrentName(name)
      const result = dropDownProps.filter((el:any) => el.name=== name);
      dispatch(setCurrentFolder(result))
      setIsOpen(!isOpen)
  }

  return <div className={style.wrapperRoot} >
  <button className={style.switcherButton} onClick={handleOpenSwitcher}>
    <div><span>{currentName.trim().length === 0?'Не выбрано':splitText(currentName, 15)}</span></div>
    <div className={isOpen?style.triangle:style.triangleRotate}></div>
  </button>
  <ul className={!isOpen?style.listDropDownEnable:style.listDropDownDisabled}>
    {dropDownProps.map((element: DropDownProps) => {
    return (
<li className={currentName === element.name ? style.active : style.liDropDown} key={element.name}><button className={style.btnLiDropDown} onClick={() => handleClick(element.name)} >{splitText(element.name, 14)}</button></li>

    )
    })}
  </ul>
</div>
}
export default DropDown
