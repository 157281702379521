import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { RootState } from '../store'

interface Directory {
  name: string;
  path: string;
  type: string;
}
interface AllDirectoryState {
  directories: Directory[];  
  loading: boolean;
  error: string | null;
}


const initialState:AllDirectoryState= {
  directories: [],
  loading: false,
  error: null,
};

export const fetchDirectories = createAsyncThunk(
  'directory/fetchDirectories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/media/getPath`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const directorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setCurrentDirectory: (state, action) => {
      state.directories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDirectories.fulfilled, (state, action: PayloadAction<Directory[]>) => {
        state.loading = false;
        state.directories = action.payload;
      })
      .addCase(fetchDirectories.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const {setCurrentDirectory} = directorySlice.actions

export const selectDirectories = (state: RootState) => state.directory.directories;
export const selectLoading = (state: RootState) => state.directory.loading;
export const selectError = (state: RootState) => state.directory.error;

export default directorySlice.reducer;
