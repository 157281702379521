import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchCreateFolder } from './CreateFolderSlice'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'

interface createFolderProps {
  path: string,
  folderName: string
}

export const createFolderSeqActionSlice = ({
  path,
  folderName,

}: createFolderProps) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  try {
    await dispatch(fetchCreateFolder({ path, folderName })).unwrap();
    await dispatch(fetchFolderCurrent(path)).unwrap();
    await dispatch(fetchDirectoriesUnder()).unwrap()
    
  } catch (error) {
    console.error('Ошибка выполнения:', error);
  }
};
