

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'

  interface pathToDeleteProps {
    createFile: string,
    createFileLoading: boolean;
    errorFolderCreate: string | null;
  }
  

  const initialState: pathToDeleteProps = {
    createFile: "",
    createFileLoading: false,
    errorFolderCreate: null,
  };
  interface createFileProps {
		path: string,
		fileName: string,
    content: string
	}
  
  export const fetchCreateFile = createAsyncThunk(
    'context/createFile',
    async (arg:createFileProps, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/createFile`,  {...arg});
        return response.data; 
      } catch (errorFolderCreate: any) {
        return rejectWithValue(errorFolderCreate.message);
      }
    }
  );
  
  export const createFileSlice = createSlice({
    name: 'createFile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCreateFile.pending, (state) => {
          state.createFileLoading = true;
          state.errorFolderCreate = null;
        })
        .addCase(fetchCreateFile.fulfilled, (state, action: PayloadAction<string>) => {
          state.createFileLoading = false;
          state.createFile = action.payload;
        })
        .addCase(fetchCreateFile.rejected, (state, action: PayloadAction<any>) => {
          state.createFileLoading = false;
          state.errorFolderCreate = action.payload;
        });
    },
  });

  
  export default createFileSlice.reducer;
  