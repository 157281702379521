import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


interface Directory {
  name: string;
  path: string;
  type: string;
}


interface AllDirectoryState {
  directoriesDropDown: Directory[];  
  loading: boolean;
  error: string | null;
}


const initialState: AllDirectoryState = {
  directoriesDropDown: [],
  loading: false,
  error: null,
};


export const fetchDropDownSlice = createAsyncThunk(
  'dropdown/fetchDropDownData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/media/getPath`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const dropDownSlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setDropDownData: (state, action) => {
      state.directoriesDropDown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDropDownSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDropDownSlice.fulfilled, (state, action: PayloadAction<Directory[]>) => {
        state.loading = false;
        state.directoriesDropDown = action.payload;
      })
      .addCase(fetchDropDownSlice.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const {setDropDownData} = dropDownSlice.actions


export default dropDownSlice.reducer;
