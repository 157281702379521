import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPath, setDeleteLastPath } from '../../app/redux/slices/CurrentDirectorySlice'
import { fetchFolderCurrent, setCurrentFolder } from '../../app/redux/slices/currentFolderInfo'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
import { setDropDownData } from '../../app/redux/slices/DropDownSlice'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { performSequentialActions } from '../../app/redux/slices/performSequentialActionSlice'
import { AppDispatch } from '../../app/redux/store'
import { BuildPath } from '../../features/BuildPath'
import getFileExtension from '../../features/FindExten'
import { FindToDeletePath } from '../../features/FindToDeletePath'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFaceNew, moveProps } from '../../types/types'
import { ContextMenu } from '../ContextMenu/ContextMenu'
import style from './Folder.module.scss'
const BottomContext: React.FC<FolderFaceNew> = ({ name, type, path, isHaveContent, contents, index }) => {
  
    const dispatch = useDispatch<AppDispatch>();
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const { currentFolderInfo, loadingInfo, errorInfo } = useSelector((state: any) => state.currentFolder);
    const [contextMenuPath, setContextMenuPath] = useState<string | null>(null);
    const {currentInfoAboutMove, loadingInfoMove, errorInfoMove} = useSelector((state:any)=>state.fetchMoveSlice)
    const PathOne = useSelector((state: any) => state.CurrentDirectorySlice);
    const [isContextMenu, setIsContextMenu] = useState<any>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [draggedItem, setDraggedItem] = useState<any>(null);
    const refBottomContext = useRef<any>(null)
   
    const PatnCurrent = useSelector((state:any)=>state.CurrentDirectorySlice)
    const [click, setClick] =useState<number>(0)
    const handleFolderClick = () => {
        
        if(name === "..."){
            setClick(click+1)
            
            dispatch(setDeleteLastPath());
          
           
          
            return
        }
        const result = getFileExtension(name)
        if(result) {
                dispatch(setCurrentImagePath(path))
            }
        if (contents && contents.length > 0 && !loadingInfo && errorInfo === null) {
            let some: any = BuildPath(name, currentFolderInfo);
            dispatch(setCurrentFolder(contents));
            dispatch(fetchDirectories())
            dispatch(setDropDownData(contents))
            dispatch(setCurrentPath(path));
         
        } else if (contents?.length === 0 && !loadingInfo && errorInfo === null && type === 'directory') {
         
            let some: any = BuildPath(name, currentFolderInfo);
            dispatch(setCurrentPath(some));
            dispatch(fetchDirectories())
            dispatch(setCurrentFolder([]));
        }
    };
    useEffect(()=>{
            

        if(click > 0 && PathOne.path.join("/").length >0) {
            dispatch(fetchFolderCurrent(PathOne.path.join("/")))
            dispatch(setCurrentPath(PathOne.path.join("/")))
            dispatch(setDropDownData(contents))
        }
          },[click])


    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const setSrc = (): string => {
        let extenOne: null | string = null;
        let typeOne: null | 'directory' = null;
        const exten: string = getFileExtension(name);
        if (exten.trim().length === 0) {
            extenOne = null;
        } else if (exten.trim().length > 0) {
            extenOne = exten;
        }
        if (type === undefined) {
            typeOne = null;
        } else if (type === 'directory') {
            typeOne = 'directory';
        }
        const result: string = arrIcons(extenOne, typeOne);
        return result;
    };

    const handleContextMenu = (event: React.MouseEvent) => {
        if(refBottomContext.current.id !== 'out') {
            event.preventDefault(); // Предотвращает открытие контекстного меню
            setIsContextMenu(!isContextMenu);
        }
    
    };

    const handlePath = () => {
        const result = BuildPath(name, currentFolderInfo);

        let arg: string | null = FindToDeletePath({ name, data: currentFolderInfo });
        setContextMenuPath(result);
    };

    const handleDragStart = (event: React.DragEvent, item: any) => {
        setDraggedItem(item);
        event.dataTransfer.setData('text/plain', JSON.stringify(item));
    };



    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
     
    };
    useEffect(()=>{
        dispatch(fetchFolderCurrent(PathOne.path.join("/")))
    },[])
  
    const handleDrop = (event: React.DragEvent, destination: any) => {

  
        event.preventDefault();
        const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));

         if(PathOne.path && PathOne.path.length !== 0 && PathOne.path[0].trim().length !== 0 && destination.name === '...') {
                
                const result = BuildPath(droppedItem.name, currentFolderInfo);
                let newSome:string[] = [];
                if(result !== null) {
                    newSome = result.split("/")
                }
       
                newSome.splice(newSome.length-2, 2)
        
                let newResult = newSome.join("/")
       
             
                dispatchThunk(
                    performSequentialActions({ newPath: newResult, oldPath: droppedItem.path, PathOne })
                  );
                return

         }
         
        if(destination.type !== 'directory') {
            return alert("Нельзя в файл добавить иной объект")
         }
        const oldPath:any = BuildPath(droppedItem.name, currentFolderInfo)
        let dropInFolder:string = destination.name;
        let newPath:any = BuildPath(dropInFolder, currentFolderInfo)
        
        const dataMove:moveProps = {
            oldPath,newPath
        }
    
        if(PathOne.path.length === 0) {
           
            dispatchThunk(
                performSequentialActions({ newPath, oldPath, PathOne })
              );
            
     
            return
        }
        if(loadingInfoMove === false && errorInfoMove === null) {
            dispatchThunk(
                performSequentialActions({ newPath, oldPath, PathOne })
              );
    }
       
       
    };

    useEffect(() => {
        if (isContextMenu) {
            handlePath();
        }
    }, [isContextMenu]);
    const handleCoutContext = () => {
        setIsContextMenu(false);
    }
  
    return (
       <>
    
        <div
            id="inner"
            onClick={handleCoutContext}
            ref={refBottomContext}
            onContextMenu={handleContextMenu}
            className={name !== '...'?style.wrapperFolder:style.wrapperFolderOne}
            draggable
            onDragStart={(e) => handleDragStart(e, { name, type, path, isHaveContent, contents })}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, { name, type, path, isHaveContent, contents })}
        >
            <div id="inner" className={style.folderHeader} onDoubleClick={handleFolderClick}>
             
                <img className={style.iconFiles}  id="inner" src={setSrc()} alt="folder" />
                <span id="inner">{name}</span>
            </div>
            {type === 'directory' && isExpanded && contents && (
                <div id="inner" className={style.contents}>
                    {contents.map((element: any) => 
                        {
                            return (
                                <BottomContext
                             {...element}     
                            />
                            )
                        }
                       
                    )}
                </div>
            )}
            {isContextMenu && <ContextMenu isContext={false} path={contextMenuPath} type={type} />}
        </div>
       </>
    );
};

export default BottomContext;
