import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


interface Directory {
  name: string;
  path: string;
  type: string;
}


interface AllDirectoryState {
  directories: Directory[];  
  loading: boolean;
  error: string | null;
}


const initialState: AllDirectoryState = {
  directories: [],
  loading: false,
  error: null,
};


export const fetchDirectoriesUnder = createAsyncThunk(
  'directory/fetchDirectoriesUnder',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/media/getPath`);
      return response.data; 
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const directoryUnderSlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setCurrentDirectoryUnder: (state, action) => {
      state.directories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectoriesUnder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDirectoriesUnder.fulfilled, (state, action: PayloadAction<Directory[]>) => {
        state.loading = false;
        state.directories = action.payload;
      })
      .addCase(fetchDirectoriesUnder.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const {setCurrentDirectoryUnder} = directoryUnderSlice.actions


export default directoryUnderSlice.reducer;
