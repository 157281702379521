function splitText(text: string, length: number): string {
  if (text.length <= length) {
    return text;
  }

  // Найти последнее пробел в пределах ограниченной длины
  let splitIndex = text.lastIndexOf(' ', length);

  // Если пробел не найден, возвращаем подстроку до длины
  if (splitIndex === -1) {
    return text.slice(0, length) + '...';
  }

  return text.slice(0, splitIndex) + '...';
}

export default splitText;
