import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

interface generateLinkProps {
	generateLink: string,
	loadingLink: boolean;
	errorLink: string | null;
}


const initialState: generateLinkProps = {
	generateLink: "",
	loadingLink: false,
	errorLink: null,
};
export const fetchGenerateLink = createAsyncThunk(
	'generateLink/fetchGenerateLink',
	async (arg:string) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_SERVER}/media/generateLink?webPath=${arg}`);
			return response.data;
		} catch (error: any) {
			return error
		}
	}
);
export const generateLinkSlice = createSlice({
	name: 'pathDelete',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGenerateLink.pending, (state) => {
				state.loadingLink = true;
				state.errorLink = null;
			})
			.addCase(fetchGenerateLink.fulfilled, (state, action: PayloadAction<string>) => {
				state.generateLink = action.payload;
				state.loadingLink = false
			})
			.addCase(fetchGenerateLink.rejected, (state, action: PayloadAction<any>) => {
				state.loadingLink = false;
				state.errorLink = action.payload;
			});
	},
});


export default generateLinkSlice.reducer