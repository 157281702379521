//http://localhost:5000/media/getFolder

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'

  
  // Define a type for the slice state
  interface pathToDeleteProps {
    createFolder: string,
    createFolderLoading: boolean;
    errorFolderCreate: string | null;
  }
  
  // Define the initial state using that type
  const initialState: pathToDeleteProps = {
    createFolder: "",
    createFolderLoading: false,
    errorFolderCreate: null,
  };
  interface createFolderProps {
		path: string,
		folderName: string
	}
  
  export const fetchCreateFolder = createAsyncThunk(
    'context/createFolder',
    async (arg:createFolderProps, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/createFolder`,  {...arg});
        return response.data; 
      } catch (errorFolderCreate: any) {
        return rejectWithValue(errorFolderCreate.message);
      }
    }
  );
  
  export const folderCreateSlice = createSlice({
    name: 'createFolder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCreateFolder.pending, (state) => {
          state.createFolderLoading = true;
          state.errorFolderCreate = null;
        })
        .addCase(fetchCreateFolder.fulfilled, (state, action: PayloadAction<string>) => {
          state.createFolderLoading = false;
          state.createFolder = action.payload;
        })
        .addCase(fetchCreateFolder.rejected, (state, action: PayloadAction<any>) => {
          state.createFolderLoading = false;
          state.errorFolderCreate = action.payload;
        });
    },
  });

  
  export default folderCreateSlice.reducer;
  