
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchFolderCurrent, setCurrentFolder } from '../../app/redux/slices/currentFolderInfo'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { AppDispatch } from '../../app/redux/store'
import iconSearch from '../../shared/SearchIcon.svg'
import DropDown from '../DropDown/DropDown'
import style from './Search.module.scss'
export default function Search({directories, path}:any) {
 
    const dispatch = useDispatch<AppDispatch>();
    useEffect(()=>{
        dispatch(fetchDirectories())
    },[])
    const onChangeFolder = (e:any) => {
      let some = e.target.value.toLowerCase();
      if(some.trim().length === 0) {
        dispatch(fetchFolderCurrent(path.join("/")))
      }
      if (directories.length > 0) {
        const result = directories.filter((el:any) => el.name.toLowerCase().includes(some));
        dispatch(setCurrentFolder(result))
      }

                
    }
    return (
      <div className={style.wrapperSearch} >
        <div className={style.blockSearch} >
        <img src={iconSearch} alt="" />
      <input  onChange={onChangeFolder} type="text" placeholder='Search' />
        </div>
    
      <div className={style.wrapperDropDown} >
      {directories.length > 0 && <DropDown path={path} arg={directories}/>}
      </div>
      </div>
    );
  }
  