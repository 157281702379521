//http://localhost:5000/media/getFolder

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'
import { RootState } from '../store'
interface currentFolder {
    name: string;
    path: string;
    size: number;
    type: string;
  }
  
  // Define a type for the slice state
  interface AllDirectoryState {
    currentFolderInfo: currentFolder[];  // Adjusted to be an array
    loadingInfo: boolean;
    errorInfo: string | null;
  }
  
  // Define the initial state using that type
  const initialState: AllDirectoryState = {
    currentFolderInfo: [],
    loadingInfo: false,
    errorInfo: null,
  };
  
  // Example async thunk to fetch directories (replace with your actual fetch logic)
  export const fetchFolderCurrent = createAsyncThunk(
    'directoryOne/fetchFolderCurrentOne',
    async (arg:string, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/getFolder`, {path: arg});
        return response.data; // Assuming this returns Directory[]
      } catch (errorInfo: any) {
        return rejectWithValue(errorInfo.message);
      }
    }
  );
  
  export const folderCurrent = createSlice({
    name: 'directory',
    initialState,
    reducers: {
      setCurrentFolder: (state, action) => {
        state.currentFolderInfo = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchFolderCurrent.pending, (state) => {
          state.loadingInfo = true;
          state.errorInfo = null;
        })
        .addCase(fetchFolderCurrent.fulfilled, (state, action: PayloadAction<currentFolder[]>) => {
          state.loadingInfo = false;
          state.currentFolderInfo = action.payload;
        })
        .addCase(fetchFolderCurrent.rejected, (state, action: PayloadAction<any>) => {
          state.loadingInfo = false;
          state.errorInfo = action.payload;
        });
    },
  });
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectDirectories = (state: RootState) => state.currentFolder.currentFolderInfo;
  export const selectloadingInfo = (state: RootState) => state.currentFolder.loadingInfo;
  export const selecterrorInfo = (state: RootState) => state.currentFolder.errorInfo;
  export const {setCurrentFolder} = folderCurrent.actions
  
  export default folderCurrent.reducer;
  