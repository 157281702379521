import { FolderFace } from '../types/types';

export const BuildPath = (last: string, allPath: FolderFace[]): string | null => {
    for (const item of allPath) {
       
            if (item.name === last) {
                return item.path;
            } else if (item.contents) {
                const result = BuildPath(last, item.contents);
                if (result) {
                    return result;
                }
            }
        
    }
    return null;
}
