import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ImageProps {
    image: string;
}

const initialState: ImageProps = {
    image: "", // Начальное состояние: корневой путь
};

export const CurrentImageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setCurrentImagePath: (state, action: PayloadAction<string>) => {
          

           state.image = action.payload
        },
       
    },
});

export const { setCurrentImagePath  } = CurrentImageSlice.actions;


export default CurrentImageSlice.reducer;
