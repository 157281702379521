import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'
import { fetchRenameFile } from './renameFileSlice'

interface renameFileProps {
  path: string | null,
  newName: string,
  pathFolder: string
}

export const renameFileSeqActionSlice = ({
  path,
  newName,
  pathFolder

}: renameFileProps) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  try {
    await dispatch(fetchRenameFile({ path, newName})).unwrap();
    await dispatch(fetchFolderCurrent(pathFolder)).unwrap();
    await dispatch(fetchDirectoriesUnder()).unwrap()
    
  } catch (error) {
    console.error('Ошибка выполнения:', error);
  }
};
